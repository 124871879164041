define('dispatcher/application/adapter', ['exports', 'ember-data', 'dispatcher/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-inflector', 'dispatcher/application/promise-throttle'], function (exports, _emberData, _environment, _dataAdapterMixin, _emberInflector, _promiseThrottle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    init: function init() {
      this.set('promiseThrottle', new _promiseThrottle.default({
        requestsPerSecond: 4,
        promiseImplementation: Ember.RSVP.Promise
      }));
    },

    authorizer: 'authorizer:token',
    host: _environment.default.datastoreHost,
    namespace: 'api/v1',
    coalesceFindRequests: true,
    pathForType: function pathForType(type) {
      // model names should be underscored in URLs
      // For example: /api/v1/feed_version_imports
      var decamelized = Ember.String.decamelize(type);
      var underscored = Ember.String.underscore(decamelized);
      return (0, _emberInflector.pluralize)(underscored);
    },
    ajaxOptions: function ajaxOptions(url, type, options) {
      var hash = this._super(url, type, options);
      // only need to include api_key when making GET requests
      // because those are the most frequent type of request.
      // if we include api_key in POSTs or PUTs, Datastore will barf
      if (type === 'GET') {
        var data = {};
        if (typeof hash.data === 'string') {
          data = JSON.parse(hash.data);
        } else if (typeof hash.data !== "undefined") {
          data = hash.data;
        } else {
          data = {};
        }
        data["total"] = true;
        data["embed_issues"] = true;
        hash.data = data;
      }
      return hash;
    },
    ajax: function ajax(url, type, options) {
      return this.get('promiseThrottle').add(this._super.bind(this, url, type, options));
    }
  });
});