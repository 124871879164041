define('dispatcher/changesets/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('changeset', params['changeset_id']);
    },
    actions: {
      apply: function apply() {
        var flashMessages = Ember.get(this, 'flashMessages');
        var self = this;
        var changeset = self.currentModel;
        changeset.apply().then(function () {
          flashMessages.add({
            message: "Changeset applied!",
            type: 'success',
            sticky: true
          });
          changeset.reload();
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) applying changeset: ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});