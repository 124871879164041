define('dispatcher/activity-update/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    entity: _emberData.default.belongsTo('entity-with-activity', { async: true, polymorphic: true }),
    user: _emberData.default.belongsTo('user', { async: true }),

    entity_type: _emberData.default.attr('string'),
    entity_id: _emberData.default.attr('string'),
    entity_action: _emberData.default.attr('string'),
    by_user_id: _emberData.default.attr('number'),
    note: _emberData.default.attr('string'),
    at_datetime: _emberData.default.attr('date'),

    entityShowRoute: Ember.computed('entity_type', function () {
      return this.get('entity_type') + 's.show';
    })
  });
});