define('dispatcher/components/feed-version-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dropDownExpanded: false,
    actions: {
      toggleDropDownExpanded: function toggleDropDownExpanded() {
        this.toggleProperty('dropDownExpanded');
      },
      setAsActiveFeedVersion: function setAsActiveFeedVersion() {
        alert('TODO: make this work on the Datastore back-end!');
      },
      changeImportLevel: function changeImportLevel(importLevel) {
        var self = this;
        var feedVersion = this.get('feedVersion');
        var flashMessages = Ember.get(this, 'flashMessages');
        feedVersion.set('import_level', importLevel);
        feedVersion.save().then(function () {
          flashMessages.add({
            message: "Import level updated on feed version!",
            type: 'success',
            sticky: true
          });
          // TODO: force a reload of the feed model
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) updating import level on feed version: ' + error.message,
            type: 'danger',
            sticky: true
          });
        }).finally(function () {
          self.set('dropDownExpanded', false);
        });
      },
      enqueue: function enqueue(importLevel) {
        var self = this;
        var flashMessages = Ember.get(this, 'flashMessages');
        this.model.enqueue(importLevel).then(function () {
          flashMessages.add({
            message: 'Successfully enqueued feed version for import!',
            type: 'success',
            sticky: true
          });
        }).catch(function (e) {
          flashMessages.add({
            message: 'Error enqueuing feed version for import: ' + e.message,
            type: 'danger',
            sticky: true
          });
        }).finally(function () {
          self.set('dropDownExpanded', false);
        });
      }
    }
  });
});