define('dispatcher/components/feeds-table/component', ['exports', 'dispatcher/mixins/selectable-model-component'], function (exports, _selectableModelComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_selectableModelComponent.default, {
    session: Ember.inject.service(),
    classNames: ['table-responsive'],
    getSelectableModels: function getSelectableModels() {
      return this.get('feeds');
    },
    selectableModelDefault: false,
    actions: {
      enqueueSelectedFeedsForImport: function enqueueSelectedFeedsForImport(importLevel) {
        var flashMessages = Ember.get(this, 'flashMessages');
        var importPromises = this.get('selectedFeeds').map(function (feed) {
          return feed.content.enqueue(importLevel);
        });
        Ember.RSVP.allSettled(importPromises).then(function () {
          flashMessages.add({
            message: 'Successfully enqueued latest versions of feed(s) for import!',
            type: 'success',
            sticky: true
          });
        }).catch(function (e) {
          flashMessages.add({
            message: 'Error enqueuing feed(s) for import: ' + e.message,
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});