define('dispatcher/components/feed-version-file-upload/component', ['exports', 'ember-uploader', 'dispatcher/config/environment'], function (exports, _emberUploader, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    session: Ember.inject.service(),
    filesDidChange: function filesDidChange(files) {
      var uploader = void 0;
      this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
        uploader = _emberUploader.default.Uploader.create({
          url: _environment.default.datastoreHost + '/api/v1/feed_versions',
          paramNamespace: 'feed_version',
          ajaxSettings: {
            headers: {
              'Authorization': headerValue
            }
          }
        });
      });

      if (!Ember.isEmpty(files)) {
        var flashMessages = Ember.get(this, 'flashMessages');
        var feed_onestop_id = this.get('feed_onestop_id');
        uploader.upload(files[0], {
          feed_onestop_id: feed_onestop_id
        }).then(function (data) {
          flashMessages.add({
            message: 'Feed version successfully uploaded!',
            type: 'success',
            sticky: true
          });
          // TODO: transition to the feed version page
        }, function (error) {
          flashMessages.add({
            message: 'Error uploading feed version: ' + JSON.stringify(error),
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});