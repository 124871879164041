define('dispatcher/components/feeds-select-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      feedClicked: function feedClicked(feed) {
        this.sendAction('feedClicked', feed);
      }
    }
  });
});