define('dispatcher/change-payload/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    changeset: _emberData.default.belongsTo('changeset', { async: true }),
    payload: _emberData.default.attr('', { defaultValue: function defaultValue() {
        return {
          changes: []
        };
      } }),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    stringified_payload: Ember.computed('payload', function () {
      return JSON.stringify(this.get('payload'));
    })
  });
});