define('dispatcher/components/feed-version-import-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['panel'],
    isSuccessful: Ember.computed('feedVersionImport.success', function () {
      var success = this.get('feedVersionImport').get('success');
      if (success === true) {
        return 'panel-success';
      } else if (success === false) {
        return 'panel-danger';
      } else {
        return 'panel-default';
      }
    })
  });
});