define('dispatcher/components/feed/create-edit-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    enumOptions: {
      license_use_without_attribution: ['yes', 'no', 'unknown'],
      license_create_derived_product: ['yes', 'no', 'unknown'],
      license_redistribute: ['yes', 'no', 'unknown']
    }
  });
});