define('dispatcher/components/edit-buttons/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      showChangeset: function showChangeset() {
        this.sendAction('showChangeset');
      },
      closeDialog: function closeDialog() {
        this.sendAction('closeDialog');
      }
    }
  });
});