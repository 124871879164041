define('dispatcher/changeset/model', ['exports', 'ember-data', 'dispatcher/entity-with-activity/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    change_payloads: _emberData.default.hasMany('change-payload', { async: true }),
    user: _emberData.default.belongsTo('user', { async: true }),
    imported_from_feed: _emberData.default.belongsTo('feed', { async: true, inverse: 'changesets_imported_from_this_feed' }),
    imported_from_feed_version: _emberData.default.belongsTo('feed-version', { async: true, inverse: 'changesets_imported_from_this_feed_version' }),
    feeds_created_or_updated: _emberData.default.hasMany('feeds', { async: true, inverse: 'created_or_updated_in_changeset' }),
    // feeds_destroyed: DS.hasMany('feeds', { async: true, inverse: 'destroyed_in_changeset' }),
    // stops_created_or_updated: DS.hasMany('stops', { async: true, inverse: '' }),
    // stops_destroyed: DS.hasMany('', { async: true, inverse: '' }),
    // operators_created_or_updated: DS.hasMany('operators', { async: true, inverse: '' }),
    // operators_destroyed: DS.hasMany('operators', { async: true, inverse: '' }),
    // routes_created_or_updated: DS.hasMany('routes', { async: true, inverse: '' }),
    // routes_destroyed: DS.hasMany('routes', { async: true, inverse: '' }),

    issuesResolved: _emberData.default.attr(),
    notes: _emberData.default.attr('string'),
    applied: _emberData.default.attr('boolean'),
    applied_at: _emberData.default.attr('date'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),

    apply: function apply() {
      var applicationAdapter = this.store.adapterFor('changeset');
      var modelUrl = applicationAdapter.buildURL('changeset', this.id);
      var applyUrl = modelUrl + '/apply';
      return applicationAdapter.ajax(applyUrl, 'post');
    },

    apply_async: function apply_async() {
      var applicationAdapter = this.store.adapterFor('changeset');
      var modelUrl = applicationAdapter.buildURL('changeset', this.id);
      var applyUrl = modelUrl + '/apply_async';
      return applicationAdapter.ajax(applyUrl, 'post');
    }
  });
});