define('dispatcher/components/feed-versions-table/component', ['exports', 'dispatcher/mixins/selectable-model-component'], function (exports, _selectableModelComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_selectableModelComponent.default, {
    session: Ember.inject.service(),
    classNames: ['table-responsive'],
    sortKey: null,
    getSelectableModels: function getSelectableModels() {
      return this.get('models');
    },
    actions: {}
  });
});