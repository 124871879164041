define('dispatcher/change-payload/adapter', ['exports', 'dispatcher/application/adapter'], function (exports, _adapter) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = _adapter.default.extend({
				buildURL: function buildURL(type, ids, snapshots) {

						//can't make multiple changes
						if (snapshots instanceof Array || ids instanceof Array || !snapshots.record) {
								var changeSetId = null;
								snapshots.forEach(function (snapshot) {
										if (changeSetId === null) {
												changeSetId = snapshot.record.get('changeset.id');
										} else if (changeSetId !== snapshot.record.get('changeset.id')) {
												throw "You should not get many payloads from many changeset ids: " + changeSetId + " vs. " + snapshot.record.get('changeset.id');
										}
								});
								return this.urlPrefix() + '/changesets/' + changeSetId + '/change_payloads/';
						}

						return this.urlPrefix() + '/changesets/' + snapshots.record.get('changeset.id') + '/change_payloads/' + ids;
				}
		});
});