define('dispatcher/components/activity-update-list/activity-update-entry/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    entityUrl: Ember.computed('activityUpdate', function () {
      return this.router.generate(this.get('activityUpdate.entity_type') + 's.show', this.get());
    })
  });
});