define('dispatcher/changesets/index/controller', ['exports', 'dispatcher/mixins/paginated-sortable-controller'], function (exports, _paginatedSortableController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatedSortableController.default, {
    applied: false,
    sort_key: 'updated_at',
    sort_order: 'desc'
  });
});