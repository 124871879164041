define('dispatcher/changeset/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    enabledForModels: ['feed'], // TODO: expand to operators next
    createChangesetPayloadFromLocalEdits: function createChangesetPayloadFromLocalEdits() {
      var payload = { changes: this.getChanges() };
      return payload;
    },
    getChanges: function getChanges() {
      var _this = this;

      var entities = [];
      this.enabledForModels.map(function (modelName) {
        entities = entities.concat(_this.get('store').peekAll(modelName).filter(function (e) {
          return e.get('hasDirtyAttributes');
        }));
      });
      return entities.map(function (e) {
        var ret = {};
        ret['action'] = 'createUpdate';
        ret[e.entityType()] = e.toChange();
        return ret;
      });
    }
  });
});