define("dispatcher/components/issue-contents/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["issue-contents"],
    attributeBindings: ["style"],
    showEntities: true
  });
});