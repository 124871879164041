define('dispatcher/components/issue-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['panel'],
    classNameBindings: ['panelClass'],
    panelClass: 'panel-default',
    issue: null,
    actions: {
      issueClicked: function issueClicked(issue) {
        this.sendAction("issueClicked", issue);
      }
    }
  });
});