define('dispatcher/changesets/new/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model: function model(params) {
      var payload = {};
      if (params.payload) {
        payload = JSON.parse(decodeURI(params.payload));
      }
      var changeset = this.store.createRecord('changeset', {
        user: this.get('currentUser.user'),
        notes: params.notes
      });
      changeset.get('change_payloads').createRecord({
        payload: payload
      });
      return changeset;
    },
    actions: {
      create: function create() {
        var self = this;
        var changeset = self.currentModel;
        var flashMessages = Ember.get(this, 'flashMessages');
        changeset.save().then(function () {
          self.transitionTo('changesets.show', changeset);
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) creating changeset: ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});