define('dispatcher/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    didTransition: function didTransition() {
      this._super();
      // always scroll to the top of the page after transitioning to a new route
      window.scrollTo(0, 0);
    },

    // https://github.com/simplabs/ember-simple-auth/blob/master/guides/managing-current-user.md#loading-the-current-user
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('currentUser').load();
    }
  });
});