define('dispatcher/components/modal-message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    show: function () {
      this.$('.modal').modal().on('hidden.bs.modal', function () {
        this.sendAction('close');
      }.bind(this));
    }.on('didInsertElement')
  });
});