define('dispatcher/changesets/change-payloads/edit/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.find('change-payload', params['change_payload_id']);
    },
    actions: {
      delete: function _delete() {
        var self = this;
        var model = self.currentModel;
        var changeset = model.get('changeset');
        var flashMessages = Ember.get(this, 'flashMessages');
        model.destroyRecord().then(function () {
          flashMessages.add({
            message: 'Change payload deleted!',
            type: 'success',
            sticky: true
          });
          self.transitionTo('changesets.show', changeset);
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) deleting change payload: ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      },
      update: function update() {
        var self = this;
        var model = self.currentModel;
        var changeset = model.get('changeset');
        var flashMessages = Ember.get(this, 'flashMessages');
        model.set('payload', JSON.parse(model.get('stringified_payload')));
        model.save().then(function () {
          flashMessages.add({
            message: "Change payload updated!",
            type: 'success',
            sticky: true
          });
          self.transitionTo('changesets.show', changeset);
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) updating change payload: ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});