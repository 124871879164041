define('dispatcher/change-payload/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    attrs: {
      created_at: { serialize: false },
      updated_at: { serialize: false },
      changeset: { serialize: false }
    }
  });
});