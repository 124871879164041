define('dispatcher/components/current-user-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['nav', 'navbar-nav', 'navbar-right'],
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    actions: {
      signOut: function signOut() {
        var flashMessages = Ember.get(this, 'flashMessages');
        flashMessages.success("Signed out.");
        this.get('session').invalidate();
      }
    }
  });
});