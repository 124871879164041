define('dispatcher/components/collapsable-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['panel'],
    classNameBindings: ['panelClass'],
    panelClass: 'panel-default',
    show: false,
    actions: {
      toggleCollapse: function toggleCollapse() {
        this.toggleProperty("show");
      }
    }
  });
});