define('dispatcher/components/feed-fetcher-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    store: Ember.inject.service(),
    buttonText: 'Fetch',
    actions: {
      fetchFeeds: function fetchFeeds() {
        var adapter = this.get('store').adapterFor('feed');
        var url = adapter.urlPrefix() + '/webhooks/feed_fetcher';
        var flashMessages = Ember.get(this, 'flashMessages');
        var params = {};
        if (Ember.isPresent(this.get("feed"))) {
          params["data"] = {
            feed_onestop_id: this.get("feed.onestop_id")
          };
        } else if (Ember.isPresent(this.get("feeds"))) {
          params["data"] = {
            feed_onestop_id: this.get("feeds").mapBy("onestop_id").join(",")
          };
        }
        var self = this;
        adapter.ajax(url, 'post', params).then(function () {
          flashMessages.add({
            message: 'Starting to fetch feed(s)!',
            type: 'success',
            sticky: true
          });
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) fetching feed(s): ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});