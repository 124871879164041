define('dispatcher/components/operator-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Proxy values
    tag_key_input: null,
    tag_value_input: null,
    actions: {
      // Update tags or dates
      setTagKeyValue: function setTagKeyValue() {
        this.set('tag_key', this.get('tag_key_input'));
        this.set('tag_value', this.get('tag_value_input'));
      }
    }
  });
});