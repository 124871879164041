define('dispatcher/changesets/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['payload', 'notes'],
    payload: null,
    notes: null
  });
});