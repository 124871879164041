define('dispatcher/components/modal-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    closeText: 'Close',
    okText: 'OK',
    actions: {
      ok: function ok() {
        this.$('.modal').modal('hide');
        this.sendAction('ok');
      }
    },
    show: function () {
      this.$('.modal').modal().on('hidden.bs.modal', function () {
        this.sendAction('close');
      }.bind(this));
    }.on('didInsertElement')
  });
});