define('dispatcher/changesets/edit/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var changeset = this.store.find('changeset', params['changeset_id']);
      var users = this.store.query('user', { per_page: false });
      return Ember.RSVP.hash({
        changeset: changeset,
        users: users
      });
    },
    actions: {
      delete: function _delete() {
        var self = this;
        var changeset = self.currentModel.changeset;
        var flashMessages = Ember.get(this, 'flashMessages');
        changeset.destroyRecord().then(function () {
          flashMessages.add({
            message: "Changeset deleted!",
            type: 'success',
            sticky: true
          });
          self.transitionTo('changesets');
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) deleting changeset: ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      },
      update: function update() {
        var flashMessages = Ember.get(this, 'flashMessages');
        var self = this;
        var changeset = self.currentModel.changeset;
        changeset.save().then(function () {
          flashMessages.add({
            message: "Changeset updated!",
            type: 'success',
            sticky: true
          });
          self.transitionTo('changesets.show', changeset);
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) updating changeset: ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});