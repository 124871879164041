define('dispatcher/changeset/serializer', ['exports', 'ember-data', 'dispatcher/application/serializer'], function (exports, _emberData, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      imported_from_feed: {
        key: 'imported_from_feed_onestop_id',
        serialize: false
      },
      imported_from_feed_version: {
        key: 'imported_from_feed_version_sha1',
        serialize: false
      },
      change_payloads: {
        serialize: 'records',
        deserialize: 'ids'
      },
      user: {
        serialize: 'records',
        deserialize: 'ids'
      },
      applied: {
        serialize: false
      },
      applied_at: {
        serialize: false
      },
      created_at: {
        serialize: false
      },
      updated_at: {
        serialize: false
      },
      feeds_created_or_updated: {
        serialize: false
      },
      activity_updates: {
        serialize: false
      }
    }
  });
});